import React from 'react'

const Serveprop = (t) => {
    return (
        <>
                <div className="serve-child">
                    <div className="serve-inner-child">
                        <img src={t.img} alt="" />
                        <p>{t.text}</p>
                    </div>
                </div>
        </>
    )
}

export default Serveprop
