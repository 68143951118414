import React from 'react';
import { MdFeaturedPlayList } from "react-icons/md";
import Feaprop from './Feaprop';

const Features = () => {
  return (
   <>
        <div className="feature-main">
            <div className="container">
                <div className="feature-text">
                    <p className='feature-head'>FEATURES</p>
                    <p className='feature-small'>Standard Features</p>
                </div>
                <div className="feature-inner-main">
                        <Feaprop png={"assents/img/f1.png"} p={"Customised GST Invoice with Logo."}/>
                        <Feaprop png={"assents/img/f2..png"} p={"Generate GSTR-1,GSTR-2,GSTR-3B Reports"}/>
                        <Feaprop png={"assents/img/f3.png"} p={"Send Auto SMS in Sale bills and Receipts"}/>
                        <Feaprop png={"assents/img/f4.png"} p={"Auto Reminders For Post Dated Cheques"}/>
                        <Feaprop png={"assents/img/f5.png"} p={"Auto Reminders For Over Due Bills."}/>
                        <Feaprop png={"assents/img/f6.png"} p={"Print Reports Column as per Choice"}/>
                        <Feaprop png={"assents/img/f7.png"} p={"Set Credit Limits For Customers"}/>
                        <Feaprop png={"assents/img/f8.png"} p={"Sale Bill Printing As Per Customerwise,Billwise"}/>
                        <Feaprop png={"assents/img/f9.png"} p={"Outstanding Reports with 30,60,90 Days"}/>
                        <Feaprop png={"assents/img/f10.png"} p={"Cheque And Envelop Printing"}/>
                        <Feaprop png={"assents/img/f11.png"} p={"User Logs Reports"}/>
                        <Feaprop png={"assents/img/f12.png"} p={"Userwise Login Access Rights"}/>
                        <Feaprop png={"assents/img/f13.png"} p={"Auto Check Valid GST NO. through GST Portal."}/>
                        <Feaprop png={"assents/img/f14.png"} p={"Generate E-Way Bills"}/>
                        <Feaprop png={"assents/img/f15.png"} p={"Export All Reports In Excel, CSV, PDF Formats."}/>
                        <Feaprop png={"assents/img/f16.png"} p={"Auto Data Backup."}/>
                        <Feaprop png={"assents/img/f17.png"} p={"Generate MIS Reports."}/>
                        <Feaprop png={"assents/img/f18.png"} p={"Generate Customer, Supplier and General Ledgers"}/>
                        <Feaprop png={"assents/img/f19.png"} p={"Generate Financial Reports"}/>
                        <Feaprop png={"assents/img/f20.png"} p={"Bank Reconcilation Statement."}/>
                        <Feaprop png={"assents/img/f21.png"} p={"Backup to Google Drive."}/>
                        <Feaprop png={"assents/img/f22.png"} p={"Integration with the Third-Party Application."}/>
                        <Feaprop png={"assents/img/f23.png"} p={"Chatbot"}/>
                </div>
            </div>
        </div>
   </>
  )
}

export default Features
