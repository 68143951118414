import React, { useState } from 'react';
import { FaLocationDot } from "react-icons/fa6";
import { FaPhoneAlt } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import Map from './Map';
import FooterEnd from './FooterEnd';
import emailjs from 'emailjs-com';
import { Bounce, toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Footer = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');

    const validateForm = () => {
        if (!name.trim()) {
            toast.error('Name is required!');
            return false;
        }
        if (!email.trim()) {
            toast.error('Email is required!');
            return false;
        }
        if (!/\S+@\S+\.\S+/.test(email)) {
            toast.error('Invalid email format!');
            return false;
        }
        if (!message.trim()) {
            toast.error('Message is required!');
            return false;
        }
        return true;
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!validateForm()) return;

        const templateParams = {
            name: name,
            email: email,
            message: message,
        };

        emailjs.send('service_7h2vzau', 'template_gppgzxe', templateParams, 'sUgEpTuOoE-cG5WkJ')
            .then((response) => {
                toast.success('Message sent successfully!', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    transition: Bounce,
                });
                console.log('SUCCESS!', response.status, response.text);

                // Clear the form
                setName('');
                setEmail('');
                setMessage('');
            })
            .catch((err) => {
                toast.error('Failed to send message. Please try again later.', {
                    position: "top-right",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "light",
                    transition: Bounce,
                });
                console.error('FAILED...', err);
            });
    };

    return (
        <>
            <ToastContainer />
            <div className="footer-main" id='footer'>
                <div className="container">
                    <div className="footer-text">
                        <p className='footer-head'>Get In Touch</p>
                        <p className='footer-small'>Please fill out the form below to send us an email and we will get back to you as soon as possible.</p>
                    </div>
                    <div className="footer-inner-main">

                        <div className="footer-child">
                            <div className="footer-inner-child">
                                <form onSubmit={handleSubmit}>
                                    <table>
                                        <tbody>
                                            <tr>
                                                <td><input type="text" name="name" placeholder='Name' value={name} onChange={(e) => setName(e.target.value)} /></td>
                                            </tr>
                                            <tr>
                                                <td><input type="email" name="email" placeholder='Email' value={email} onChange={(e) => setEmail(e.target.value)} /></td>
                                            </tr>
                                            <tr>
                                                <td><input name='message' placeholder='Message' value={message} onChange={(e) => setMessage(e.target.value)} /></td>
                                            </tr>
                                            <tr>
                                                <td><button type="submit">Send Message</button></td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </form>
                            </div>
                        </div>
                        <div className="footer-child">
                            <div className="footer-inner-child">
                                <div className="footer-parts">
                                    <div className="footer-info">
                                        <p className='info'> <FaLocationDot className='infoicon' />Address</p>
                                        <p className='infotext'>410, The Boulevard, Pratham Circle Opp Pal RTO, Pal, Green City Rd, Adajan, Surat, Gujarat 395009 </p>
                                    </div>
                                    <div className="footer-info">
                                        <p className='info'> <FaPhoneAlt className='infoicon' />Phone</p>
                                        <p className='infotext'>+91-9638177750 </p>
                                        <p className='infotext'>+91-8128061833</p>
                                    </div>
                                    <div className="footer-info">
                                        <p className='info'> <FaPhoneAlt className='infoicon' />Business Enquiry</p>
                                        <p className='infotext'>+91-9924577750 </p>
                                    </div>
                                    <div className="footer-info">
                                        <p className='info'> <MdOutlineEmail className='infoicon' />Email</p>
                                        <p className='infotext'>info@galaxyerpsoftware.com </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Map />
            <FooterEnd />
        </>
    );
};

export default Footer;
