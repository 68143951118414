import React from 'react'

const Appprop = (i) => {
    return (
        <>
            <div className="app-child">
                <div className="app-inner-child">
                    <img src={i.img}  alt="" className='app-gif'/>
                    <h3>{i.h3}</h3>
                    <p>{i.p}</p>
                </div>
            </div>
        </>
    )
}

export default Appprop
