import React from 'react'

const Product = () => {
    return (
        <>
            <div className="product-main">
                <div className="container">
                    <div className="product-text">
                        <p>PRODUCTS</p>
                    </div>
                    <div className="product-inner-main">
                        <div className="product-child">
                            <div className="product-inner-child">
                                <img src="assents/img/p1.jpg" alt="" />
                                <div className="product-inner-text">
                                    <p className='product-head'>Web Applications</p>
                                    <p className='product-small'>Unlock flexibility with our ERP software's intuitive web applications, granting seamless data access and management from any device, anywhere, anytime. Experience enhanced collaboration and productivity as your team stays connected on the go. With responsive design and robust security, empower your business to thrive in a digital-first world. Simplify data handling and maximize efficiency with our versatile web-based solution.</p>
                                </div>
                            </div>
                        </div>
                        <div className="product-child">
                            <div className="product-inner-child">
                                <div className="product-inner-text">
                                    <p className='product-head'>Mobile Applications</p>
                                    <p className='product-small'>Experience the freedom of managing your business data anytime, anywhere with our ERP software's mobile applications. Designed for seamless integration into your workflow, our mobile solutions empower employees and engage customers on the move. Stay ahead with real-time insights and responsive interfaces that adapt to your needs. Elevate efficiency and connectivity with our user-friendly mobile tools, revolutionizing how you do business in a dynamic world.</p>
                                </div>
                                <img src="assents/img/p2.jpg" alt="" />
                            </div>
                        </div>
                        <div className="product-child">
                            <div className="product-inner-child">
                                <img src="assents/img/p3.jpg" alt="" />
                                <div className="product-inner-text">
                                    <p className='product-head'>Sales And Procurements</p>
                                    <p className='product-small'>Revolutionize your supply chain operations with our advanced ERP software, designed to elevate sales and procurement management. Seamlessly integrate processes, from purchase orders to sales transactions, for unparalleled efficiency. Empower your team with real-time insights and automated workflows, driving productivity and profitability. Experience streamlined operations and accelerated growth with our cutting-edge solution</p>
                                </div>
                            </div>
                        </div>
                        <div className="product-child">
                            <div className="product-inner-child">
                                <div className="product-inner-text">
                                    <p className='product-head'>Finance And HR</p>
                                    <p className='product-small'>Empower your business with our integrated ERP software, delivering robust solutions for finance and HR management. From meticulous financial record-keeping to streamlined employee data management, we've got you covered. Drive efficiency and compliance with our tailored tools, enabling you to stay ahead in a dynamic business landscape. Experience seamless financial and HR operations, tailored to optimize your organizational performance</p>
                                </div>
                                <img src="assents/img/p4.jpg" alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Product
