import React from 'react'
import Moduleprop from './Moduleprop'

const Module = () => {
    return (
        <>
            <div className="module-main">
                <div className="container">
                    <div className="module-text">
                        <p>GalaxyERP Modules</p>
                    </div>
                    <div className="module-inner-main">
                        <Moduleprop img={"assents/img/module1.jpg"} name={"Base"}/>
                        <Moduleprop img={"assents/img/module2.jpg"} name={"Sales"}/>
                        <Moduleprop img={"assents/img/module3.jpg"} name={"Production Planning"}/>
                        <Moduleprop img={"assents/img/module4.jpg"} name={"Production"}/>
                        <Moduleprop img={"assents/img/module5.jpg"} name={"Purchasing Procurement"}/>
                        <Moduleprop img={"assents/img/module6.jpg"} name={"Inventory transportation"}/>
                        <Moduleprop img={"assents/img/module7.jpg"} name={"Costing"}/>
                        <Moduleprop img={"assents/img/module8.jpg"} name={"Finance "}/>
                        <Moduleprop img={"assents/img/module9.jpg"} name={"Plant maintenance"}/>
                        <Moduleprop img={"assents/img/module10.jpg"} name={"Payroll & HR"}/>
                        <Moduleprop img={"assents/img/module11.jpg"} name={"CRM"}/>
                        <Moduleprop img={"assents/img/module12.jpg"} name={"Business Dashboard "}/>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Module
