import React from "react";
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Slide = () => {
    const options = {
        loop: true,
        margin: 20,
        items: 4,
        nav: false,
        dots: false,
        autoplay: true,
        autoplayTimeout: 2000,
        autoplayHoverPause: true,// This stops autoplay on hover,
        smartSpeed: 900,

        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 1
            },
            700: {
                items: 1
            },
            1000: {
                items: 1
            }
        }
    };
    return (
        <>
            <OwlCarousel className='owl-theme' {...options}>
                <div class='item'>
                    <h4>
                        <img src="assents/gif/erp1.gif" alt="" />
                    </h4>
                    <div className="slide-text">
                        <p>SOFTWARE PRODUCT DEVELOPMENT SERVICES</p>
                        <ul className="slide-lists">
                            <li><span>10+</span> Years of IT Experience</li>
                            <li>Domain & Technology Expert Team</li>
                            <li>Textile Industries Experts</li>
                        </ul>
                    </div>
                </div>
                <div class='item'>
                    <h4><img src="assents/gif/erp2.gif" alt="" /></h4>
                    <div className="slide-text">
                        <p>Enabling Business WITH EMERGING TECHNOLOGY</p>
                        <ul className="slide-lists">
                            <li>Putting Our Expertise to Work</li>
                            <li>Continuous Research & Development</li>
                            <li><span>User-Friendly</span>  Interface</li>
                            <li>Bridge the Gap Between Technology & Business</li>
                        </ul>
                    </div>
                </div>
                <div class='item'>
                    <h4><img src="assents/gif/erp3.gif" alt="" /></h4>
                    <div className="slide-text">
                        <p>FROM IDEA TO IMPLEMENTATION OUR COMPETITIVE EDGE</p>
                        <ul className="slide-lists">
                            <li>Control & Visibility Over The Entire Life Cycle</li>
                            <li>Quality, Cost Effective & Better Development</li>
                            <li>Domain Expert Team</li>
                            <li> <span>24x7</span> Technical Support</li>
                        </ul>
                    </div>
                </div>
            </OwlCarousel>
        </>
    )
}

export default Slide;

