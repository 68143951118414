import React from 'react';
import { MdOutlineMailOutline } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import { DownOutlined } from '@ant-design/icons';
import { MenuProps } from 'antd';
import { Dropdown, Space } from 'antd';
import { Button, Flex } from 'antd';
import { Link } from 'react-scroll';


const Head = () => {
    const items: MenuProps['items'] = [
        {
            label: <a href="https://outlook.office.com/mail/oobe?reuseWindow=true%2F%3FnativeVersion%3D1.2024.717.400&sessionId=3e8a9767-8187-db4c-8198-ef52e576d8fb&oobeRedirect=NA&loadModule=%2Fmail%2F"><MdOutlineMailOutline className='headbtn' />info@galaxyerpsoftware.com</a>,
            key: '0',
        },
        {
            label: <a href="https://www.aliyun.com"><IoCall className='headbtn1' />+919924577750</a>,
            key: '1',
        },
    ];
    return (
        <>

            <div className="head-main">
                {/* <div className="container"> */}
                    <div className="head-inner-main">
                        <div className="head-child">
                            <div className="head-inner-child">
                                <div className="head-con">
                                    <div className="head-parts">
                                        <img src="assents/img/Galaxy3.png" alt="" className='logo-header'/>
                                       {/* <p> <strong>GalaxyERP</strong> <span className='headmain'>Software Private Limited</span></p> */}
                                    </div>
                                    <div className="head-parts">
                                        <div className="head-buttons">
                                            <div className="head-btn">
                                                <a href="https://cal.com/galaxyerp-team-brxd8h/30min?date=2024-12-05&month=2024-12">
                                                    <Button type="primary">Make a Inquiry
                                                    </Button></a>
                                            </div>
                                            <div className="head-btn">
                                                <Dropdown menu={{ items }} trigger={['click']} className='drop'>
                                                    <a onClick={(e) => e.preventDefault()}>
                                                        <Space className='dropinfo'>
                                                            More Info
                                                            <DownOutlined />
                                                        </Space>
                                                    </a>
                                                </Dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="head-child">
                            <div className="head-inner-child">
                                <div className="nav-menu">
                                    <ul className="main-menu">
                                        <li>
                                            <Link to="home"
                                                spy={true}
                                                smooth={true}
                                                offset={-150}
                                                duration={500} >Home</Link>
                                        </li>
                                        <li>
                                            <Link to="about" spy={true}
                                                smooth={true}
                                                offset={-100}
                                                duration={500}>about</Link>
                                        </li>
                                        <li>
                                            <Link to='service' spy={true}
                                                smooth={true}
                                                offset={-100}
                                                duration={500}>services</Link>
                                        </li>
                                        <li>
                                            <Link to='techno' spy={true}
                                                smooth={true}
                                                offset={-100}
                                                duration={500}>technologies</Link>
                                        </li>
                                        <li>
                                            <Link to='footer' spy={true}
                                                smooth={true}
                                                offset={-100}
                                                duration={500}>contact</Link>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                {/* </div> */}
            </div>


        </>
    )
}

export default Head;
