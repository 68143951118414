import React from 'react';
import { MdOutlineCheck } from "react-icons/md";
import Slide1 from './Slide1';

const About = () => {
  return (
    <>
        <div className="about-main" id='about'>
            <div className="container">
                <div className="about-inner-main">
                    <div className="about-child">
                        <div className="about-inner-child">
                            <img src="assents/img/about.jpg" alt="" />
                        </div>
                    </div>
                    <div className="about-child">
                        <div className="about-inner-child">
                            <p className='about-head'>ABOUT US</p>
                            <p className='about-text'>At GalaxyERP Software Private Limited, Empower Your Business with Next-Gen ERP Solutions, Redefining Efficiency and Innovation. Seamlessly Integrate Data and Automation to Propel Your Business Towards Unprecedented Success with State-of-the-Art ERP Technology.</p>
                            <ul>
                                <p className='about-lists'>Why Choose Us?</p>
                            <li><MdOutlineCheck className='about-icon'/>Multiple Production Houses</li>
                            <li><MdOutlineCheck className='about-icon'/>Multiple Warehouse Management</li>
                            <li><MdOutlineCheck className='about-icon'/>Production Process Defination</li>
                            <li><MdOutlineCheck className='about-icon'/>Job Work Management</li>
                            <li><MdOutlineCheck className='about-icon'/>Generates financial reports</li>
                            <li><MdOutlineCheck className='about-icon'/>Better Inventory Management</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div className="erp-main">
            <div className="container">
                <div className="erp-text">
                    <p>Life at GalaxyERP Software Private Limited</p>
                </div>
                <div className="erp-inner-main">
                    <Slide1/>
                </div>
            </div>
        </div>
    </>
  )
}

export default About
