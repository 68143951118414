import React from 'react'

const Feaprop = (i) => {
  return (
    <>
        <div className="feature-child">
            <div className="feature-inner-child">
             <img src={i.png} alt="" />   
                <p>{i.p}</p>
            </div>
        </div>
    </>
  )
}

export default Feaprop
