import React from 'react'

const Tab1 = () => {
    return (
        <>
            <div className="tab1-main">
                <div className="conatiner">
                    <div className="tab1-inner-main">
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                    <img src="assents/img/tab2.jpg" alt="" />
                                    <p>React</p>
                            </div>
                        </div>
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                    <img src="assents/img/tab1.jpg" alt="" />
                                    <p>Android Studio</p>
                            </div>
                        </div>
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                    <img src="assents/img/tab3.jpg" alt="" />
                                    <p>Figma</p>
                            </div>
                        </div>
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                    <img src="assents/img/tab4.jpg" alt="" />
                                    <p>Flutter</p>
                            </div>
                        </div>
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                    <img src="assents/img/tab2.jpg" alt="" />
                                    <p>React Native</p>
                            </div>
                        </div>
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                    <img src="assents/img/tab12.jpg" alt="" />
                                    <p>VB .NET</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tab1
