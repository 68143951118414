import React from 'react'

const Tab2 = () => {
    return (
        <>

            <div className="tab1-main">
                <div className="conatiner">
                    <div className="tab1-inner-main">
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                <img src="assents/img/tab5.jpg" alt="" />
                                <p>PHP</p>
                            </div>
                        </div>
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                <img src="assents/img/tab6.jpg" alt="" />
                                <p>Node.js</p>
                            </div>
                        </div>
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                <img src="assents/img/tab7.jpg" alt="" />
                                <p>Express.js</p>
                            </div>
                        </div>
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                <img src="assents/img/tab8.jpg" alt="" />
                                <p>.Net Core</p>
                            </div>
                        </div>
                        <div className="tab1-child">
                            <div className="tab1-inner-child">
                                <img src="assents/img/tab9.jpg" alt="" />
                                <p>Azure</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Tab2
