import React from "react";

const Map = () => {
    return (
        <>

            <div className="container">

                <div style={{ width: "100%", height: "400px", margin: "70px 0px 0px 0px" , textAlign: 'center'}}>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3720.068823247814!2d72.78030597382251!3d21.18942478224367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be04df6b3f81ead%3A0x3265271ba545962f!2sGalaxyERP%20Software%20Private%20Limited!5e0!3m2!1sen!2sin!4v1734001353575!5m2!1sen!2sin"
                        width="70%"
                        height="70%"
                        style={{ border: "1" }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                        title="Google Map"
                    ></iframe>
                </div>
            </div>
        </>
    );
};

export default Map;
