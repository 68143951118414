import React from 'react';
import { Tabs } from 'antd';
import Tab1 from './Tab1';
import Tab2 from './Tab2';
import Tab3 from './Tab3';
import Slide2 from './Slide2';

const Tab = () => {
    const onChange = (key) => {
        console.log(key);
    };
    const items = [
        {
            key: '1',
            label: 'Frontend',
            children: <Tab1/>,
        },
        {
            key: '2',
            label: 'Backend',
            children: <Tab2/>,
        },
        {
            key: '3',
            label: 'Database',
            children: <Tab3/>,
        },

    ];
    return (
        <>
        <div className="container">
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} className='anttemp'/>
        </div>
        </>
    )
}

export default Tab
