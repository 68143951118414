import React from "react";
import { FaCartArrowDown ,FaLanguage } from "react-icons/fa6";
import { FaChartPie,FaCloudDownloadAlt  } from "react-icons/fa"
import Serprop from "./Serprop";
import Features from "./Features";
import Product from "./Product";
import Module from "./Module";


const Service = () =>{
    return(
        <>
        <div className="block-main" id='service'>
            <div className="container">
                <div className="block-text">
                    <h2>OUR SERVICES</h2>
                    <p>Streamlines Your business.</p>
                </div>
                <div className="block-inner-main">
               <Serprop icon={<FaCartArrowDown/>} head={"Machine integration"} small={"Increase productivity and lower costs by managing your sales and purchase cycles, from purchase to sales orders.improve collaboration between sales, purchasing, and warehousing for seamless order management."}/>

               <Serprop icon={<FaChartPie />} head={"ERP Services"} small={"Our custom application and consulting services are designed to meet the unique needs of your business. With our expertise and experience, we can help you optimize your operations, streamline your processes, and achieve unprecedented success. "}/>

               <Serprop icon={<FaChartPie/>} head={"Custom Application and consulting Services"} small={"At GalaxyERP Software Private Limited, we understand that your business is unique and requires custom solutions. Our team of experienced consultants and developers will work closely with you to design and develop a bespoke ERP system that meets your specific needs."}/>

               <Serprop icon={<FaCloudDownloadAlt />} head={"Pay Roll"} small={"Automate payroll processes seamlessly with our ERP software, ensuring accurate salary calculations, compliance, and streamlined payroll management for your business"}/>

               <Serprop icon={<FaLanguage />} head={"Manufacturing"} small={"Effectively maintain and manage multilevel bill of materials, production planning, job cards & inventory."}/>

               <Serprop icon={<FaChartPie/>} head={"Mobile Applications"} small={"Enhance your business operations with mobile applications that allow you to manage your sales cycle, inventory, and other essential tasks from anywhere, anytime."}/>

               <Serprop icon={<FaChartPie/>} head={"Web Applications"} small={"Streamline your business processes with web applications that provide a user-friendly interface for managing your inventory, sales, and other essential tasks."}/>

               <Serprop icon={<FaChartPie/>} head={"HR"} small={"Manage full employee life cycle right from onboarding, payroll, attendance, expense claims, assets to separation."}/>

               <Serprop icon={<FaChartPie/>} head={"Helpdesk"} small={"Deliver a better service experience with an intuitive issue tracker and an integrated knowledge base."}/>

               <Serprop icon={<FaChartPie/>} head={"Maintenance Support Services"} small={"Ensure the smooth operation of your ERP system by offering maintenance support services, including regular updates, bug fixes, and performance enhancements."}/>

               <Serprop icon={<FaChartPie/>} head={"Chatbot Services"} small={"Improve customer service and communication with chatbot services that can assist customers, answer questions, and provide real-time information."}/>

               <Serprop icon={<FaCartArrowDown/>} head={"Financial Accounting"} small={"Get a real-time view of your cash flow. Full-fledged accounting module covering every aspect of bookkeeping."}/>
              
                </div>
            </div>
        </div>

        <Module/>
        <Features/>
        <Product/>
        </>
    )
}

export default Service;