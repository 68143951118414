import React from 'react';
import { FaFacebookF  , FaTwitter  , FaLinkedinIn } from "react-icons/fa";
import { IoLogoYoutube } from "react-icons/io5";
import { Link } from 'react-scroll';

const FooterEnd = () => {
    return (
        <>
            <div className="end-main">
                <div className="container">
                    <div className="end-inner-main">
                        <div className="end-child">
                            <div className="end-inner-child">
                                <p>Copyright © 2024 GalaxyERP Software Private Limited. All Rights Reserved.</p>
                            </div>
                        </div>
                        <div className="end-child">
                            <div className="end-inner-child">
                                <ul className="end-list">
                                    <li>
                                       <Link to=''><FaFacebookF /></Link> </li>
                                    <li>
                                    <Link to=''><FaTwitter /></Link>
                                        </li>
                                    <li>
                                    <Link to=''><FaLinkedinIn /></Link>
                                        </li>
                                    <li>
                                    <Link to=''><IoLogoYoutube /></Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FooterEnd
