import React from 'react';


const Serprop = (b) => {
    return (
        <>
            <div className="block-child">
                <div className="block-inner-child">
                    <p className='block-icon'>{b.icon}</p>
                    
                    <p className='block-head'>{b.head}</p>
                    <p className='block-small'>{b.small}</p>
                </div>
            </div>
        </>
    )
}

export default Serprop
